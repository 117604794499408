import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalModule } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AppInsightsService } from './services/app-insights.service';
import { SharedModule } from './shared/shared.module';
import { AppConfigService } from './services/configuration/app-config.service';
import { EligibilityCriteriaComponent } from './components/eligibility-criteria/eligibility-criteria.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { WorkflowCarouselComponent } from './components/workflow-carousel/workflow-carousel.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { PropertyAndContactComponent } from './components/property-and-contact/property-and-contact.component';
import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { EligibilityFormComponent } from './components/eligibility-form/eligibility-form.component';
import { CenterContentComponent } from './components/center-content/center-content.component';
import { CarouselWorkflowComponent } from './components/carousel-workflow/carousel-workflow.component';
import { PrimeNgModule } from './shared/prime-ng.module';
import { GoodnewsComponent } from './components/goodnews/goodnews.component';
import { NotEligiblePropertyComponent } from './components/not-eligible-property/not-eligible-property.component';
import { FailThankYouComponent } from './components/fail-thank-you/fail-thank-you.component';
import { SuccessPropertyManagerComponent } from './components/success-property-manager/success-property-manager.component';
import { SuccessTenantComponent } from './components/success-tenant/success-tenant.component';
import { SuccessContractorComponent } from './components/success-contractor/success-contractor.component';
import { httpInterceptorProviders } from './core/interceptors';
import { GlobalErrorHandlerService } from './core/error-handler/global-error-handler.service';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ProjectDashboardComponent } from './components/dashboard/project-dashboard/project-dashboard.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { ProgramDashboardComponent } from './components/dashboard/program-dashboard/program-dashboard.component';
import { ReviewSolutionComponent } from './components/dashboard/review-solution/review-solution.component';
import { ThankYouComponent } from './components/dashboard/thank-you/thank-you.component';
import { ClientRegistrationComponent } from './components/client-registration/client-registration.component';
import { environment } from 'src/environments/environment';
import { ViewSolutionComponent } from './components/customerdashboard/view-solution/view-solution.component';
import { CustomerDashboardComponent } from './components/customerdashboard/customer-dashboard/customer-dashboard.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { IncentiveSuccessComponent } from './components/customerdashboard/incentive-success/incentive-success.component';
import { AssistYouComponent } from './components/customerdashboard/assist-you/assist-you.component';
import { NextStepsComponent } from './components/customerdashboard/next-steps/next-steps.component';
import { ContractDetailsComponent } from './components/customerdashboard/contract-details/contract-details.component';
import { SolutionComponent } from './components/customerdashboard/solution/solution.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';  
import { UpdatedContractDetailsComponent } from './components/customerdashboard/updated-contract-details/updated-contract-details.component';
import { IncentiveReservedComponent } from './components/customerdashboard/incentive-reserved/incentive-reserved.component';
import { ContractorBiddingComponent } from './components/customerdashboard/contractor-bidding/contractor-bidding.component';
import { UpdatedContractorBiddingComponent } from './components/customerdashboard/updated-contractor-bidding/updated-contractor-bidding.component';
import { NewProjectComponent } from './components/customerdashboard/new-project/new-project.component';
import { ClaimIncentiveComponent } from './components/customerdashboard/claim-incentive/claim-incentive.component';
import { FinalInspectionComponent } from './components/customerdashboard/final-inspection/final-inspection.component';
import { ProgramDetailsComponent } from './components/customerdashboard/program-details/program-details.component';
import { UploadedDocumentsComponent } from './shared/components/uploaded-documents/uploaded-documents.component';
import { ProjectSolutionComponent } from './components/customerdashboard/project-solution/project-solution.component';
import { ProjectInfoComponent } from './shared/components/project-info/project-info.component';
import { ConfirmRegistrationComponent } from './components/customerdashboard/confirm-registration/confirm-registration.component';
import { UnavailableComponent } from './components/unavailable/unavailable.component';


export function init_app(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    EligibilityCriteriaComponent,
    JumbotronComponent,
    WorkflowCarouselComponent,
    WorkflowComponent,
    PropertyAndContactComponent,
    AdditionalInformationComponent,
    ConfirmationComponent,
    EligibilityFormComponent,
    CenterContentComponent,
    CarouselWorkflowComponent,
    GoodnewsComponent,
    NotEligiblePropertyComponent,
    FailThankYouComponent,
    SuccessPropertyManagerComponent,
    SuccessTenantComponent,
    SuccessContractorComponent,
    ComingSoonComponent,
    ProjectDashboardComponent,
    DashboardComponent,
    ProgramDashboardComponent,
    ReviewSolutionComponent,
    ThankYouComponent,
    ClientRegistrationComponent,
    CustomerDashboardComponent,
    ViewSolutionComponent,
    PrivacyPolicyComponent,
    IncentiveSuccessComponent,
    AssistYouComponent,
    NextStepsComponent,
    ContractDetailsComponent,
    SolutionComponent,
    UpdatedContractDetailsComponent,
    IncentiveReservedComponent,
    ContractorBiddingComponent,
    UpdatedContractorBiddingComponent,
    NewProjectComponent,
    ClaimIncentiveComponent,
    FinalInspectionComponent,
    ProgramDetailsComponent,
    UploadedDocumentsComponent,
    ProjectSolutionComponent,
    ProjectInfoComponent,
    ConfirmRegistrationComponent,
    UnavailableComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    TypeaheadModule.forRoot(),
    MsalModule.forRoot({
      clientID: environment.adb2c.clientID,
      authority: environment.adb2c.AdSignInSignUpUrl,
      validateAuthority: false,
      redirectUri: window.location.origin,
      cacheLocation : 'sessionStorage',
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true,
      popUp: false,
      unprotectedResources: ['https://www.microsoft.com/en-us/'],
      correlationId: '1234',
      piiLoggingEnabled: environment.adb2c.EnablePII,
      extraQueryParameters: '&env=tep'
    }
  )
  ],
  providers: [
    AppInsightsService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppConfigService], multi: true },
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private appConfigService: AppConfigService) {
    if (this.appConfigService) {
      this.appConfigService.load(); // Ensure appConfigService is defined
    } else {
      console.error('AppConfigService is not initialized');
    }
  }
}
